import React, { useEffect, useRef, useState } from 'react';
import '../Dashboard_Pages/assets/css/tailwind/tailwind.min.css';
import '../Dashboard_Pages/assets/css/theme.css';
import '../Dashboard_Pages/assets/css/loopple/loopple.css';
import { SideNavPhone, TopNav } from '../component/SideNav';
import BGIMAGE from '../images/PHONEBG.png'
import { Device } from 'twilio-client';
import "../css/other.css"
import { useNavigate } from 'react-router-dom';
import { validateTokenNew } from '../auth/authentication';
import { AleartMessage } from '../component/Aleart';
import WebSocketService from '../component/WebSocketService';
import axios from 'axios';
import { API_BASE_URL } from '../component/constants';
import InfinityLoader from '../component/COMP/loadingPage';


const CallApp = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [currentNumber, setCurrentNumber] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [callStatus, setCallStatus] = useState('idle');
    const [logMessages, setLogMessages] = useState('');
    const [logMessagesError, setLogMessagesError] = useState('');
    const [twilioToken, setTwilioToken] = useState(null);
    const [dtmfDigits, setDtmfDigits] = useState('');
    const [numbers, setNumbers] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [currentbalance, setCurrentBalance] = useState();
    const [user, setUser] = useState();
    const [access, setAccess] = useState([]);
    const [record, setRecord] = useState(false);
    const [callTimeoutId, setCallTimeoutId] = useState(null);
    const [googleSheet, setGoogleSheet] = useState(null);
    const [googleSheetSelector, setGoogleSheetSelector] = useState(true);

    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const deviceRef = useRef(null);

    useEffect(() => {
        const validateTokenAndFetch = async () => {
            const response = await validateTokenNew(navigate, token, "PHONE");
            try {
                setNumbers(response.company);
                setAccess(response.access)
                handleBalance(response.company.credits);
                localStorage.setItem('compId', response.company.id)
                setUser(response.id)
                setRecord(response.record);
                setEnabled(true);
                setGoogleSheet(localStorage.getItem('googleSheet'));
            } catch (err) {
                window.location.href = '/dashboard'
            }
        };
        validateTokenAndFetch();
        fetchTwilioToken();
    }, [navigate, token]);

    useEffect(() => {
        if (twilioToken) {
            deviceRef.current = new Device(twilioToken);
            deviceRef.current.on('ready', () => {
                logMessage('Device is ready');
            });

            deviceRef.current.on('error', (error) => {
                logMessageError(`Error: ${error.message}`);
                window.location.href = '/phone-manager';
            });

            deviceRef.current.on('connect', (conn) => {
                setLogMessages('');
                logMessage('Call In Progress');
                setCallStatus('connected');
            });

            deviceRef.current.on('disconnect', () => {
                logMessage('Device is ready');
                setCallStatus('idle');
            });

        }

        return () => {
            if (deviceRef.current) {
                deviceRef.current.destroy();
            }

        };
    }, [twilioToken]);

    useEffect(() => {
        const webSocketService = WebSocketService();
        webSocketService.connect();

        webSocketService.connect(
            () => {
                webSocketService.subscribeToBalance((amount) => {
                    handleBalanceWeb(amount);
                });
            },
            (error) => {
                console.error("WebSocket connection error:", error);
            }
        );
    }, []);

    const fetchTwilioToken = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/twilio/token`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setTwilioToken(response.data.token);
        } catch (error) {
            logMessageError(`Error fetching token: ${error.message}`);
        }
    };

    const handleDigitPress = (digit) => {
        setDtmfDigits(digit);
    };

    const handleSendDigits = () => {
        if (deviceRef.current && callStatus === 'connected') {
            deviceRef.current.activeConnection().sendDigits(dtmfDigits);
            setDtmfDigits('');
        }
    };

    const handleCall = () => {
        if (!twilioToken) {
            logMessageError("token not available. Unable to make a call.");
            window.location.href = '/phone-manager';
            return;
        }
        try {
            if (callStatus === 'idle') {
                if (currentbalance > 1) {
                    if (currentNumber !== "" && currentNumber !== undefined) {
                        const params = { To: "+" + phoneNumber, From: currentNumber, User: user, Record: record };
                        deviceRef.current.connect(params);
                        setCallStatus('calling');

                        if (callTimeoutId) {
                            clearTimeout(callTimeoutId);
                        }

                        const timeoutId = setTimeout(() => {
                            deviceRef.current.disconnectAll();
                            setCallStatus('idle');
                            AleartMessage({ icon: "info", title: "Call Ended", text: "Your call has been automatically ended after 15 minutes." });
                        }, 900000);
                        setCallTimeoutId(timeoutId);

                    } else {
                        AleartMessage({ icon: "error", title: "Oops...", text: "Please select a phone number" });
                    }
                } else {
                    AleartMessage({ icon: "warning", title: "Low Balance", text: "Please recharge the account" });
                }
            } else if (callStatus === 'calling' || callStatus === 'connected') {
                deviceRef.current.disconnectAll();
                setCallStatus('idle');
            }
        } catch (error) {
            logMessageError(error.message);
            window.location.href = '/phone-manager';
        }
    };

    const logMessage = (message) => {
        setLogMessages(message);
    };

    const logMessageError = (message) => {
        setLogMessagesError(message);
    };


    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const handleNumberClick = (value) => {
        setCurrentNumber(value);
    }

    const handleNumberEvent = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        setPhoneNumber(numericValue);
    }

    const handleBalance = (balance) => {
        const formattedCredits = parseFloat(balance).toFixed(4);
        setCurrentBalance(formattedCredits);
    }

    const handleBalanceWeb = (balance) => {
        const { credits, id } = balance;
        const compId = parseInt(localStorage.getItem('compId'), 10);
        if (id === compId) {
            const formattedCredits = parseFloat(credits).toFixed(4);
            setCurrentBalance(formattedCredits);
        }

    };

    const handleGooglesheetOpen = () => {
        setGoogleSheetSelector(!googleSheetSelector);
    }
    
    const handleGooglesheetChange = (event) => {
       setGoogleSheet(event.target.value);
    }

    const handleGooglesheetClick = () => {
        const googleSheetRegex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9-_]+\/?/;
        if (googleSheet && googleSheetRegex.test(googleSheet)) {
            setGoogleSheet(googleSheet);
            setGoogleSheetSelector(false);
            localStorage.setItem('googleSheet', googleSheet);
        } else {
            AleartMessage({
                icon: "error",
                title: "ERROR",
                text: "Please enter a valid Google Sheet URL.",
            });
        }
    };
    
    return (
        <div>
            {enabled ? (
                <div className='maincontainer'>
                    <SideNavPhone item={1} access={access} />
                    <div className="ease-soft-in-out xl:ml-68.5 relative h-full max-h-screen rounded-xl transition-all duration-200" id="panel">
                        <TopNav companyName={numbers.name} balance={currentbalance} />
                        <div className="flex flex-wrap -mx-3 mb-5 removable">
                            <div className="w-full max-w-full px-3 mb-6 lg:w-8/12 sm:flex-none xl:mb-0 drop-zone">
                                <div className="w-full px-6 py-6 mx-auto loopple-min-height-78vh text-slate-500">
                                    <div className="shadow-lg relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-4">
                                        <div className="flex-auto px-0 pt-0 pb-2">
                                            <div className="p-0 overflow-x-auto -my-5">
                                                {googleSheetSelector ? (
                                                    <div className="relative my-1 flex shadow-lg flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border mb-4 draggable" draggable="true">
                                                        <div style={{ backgroundImage: `url(${BGIMAGE})`, backgroundSize: 'cover', }} className="flex-auto p-4 rounded-2xl">
                                                            <div className='wavy italic mb-2 mx-3 wavy bg-gradient-to-r from-yellow-200 to-red-300 text-transparent bg-clip-text font-bold animate-bounce motion-safe'>
                                                            Google Sheet
                                                            </div>
                                                            <div className="flex flex-row mx-3">
                                                                <div className="w-full max-w-full mb-0 sm:flex-none xl:mb-0 drop-zone">
                                                                    <input
                                                                        type="text"
                                                                        value={googleSheet}
                                                                        onChange={handleGooglesheetChange}
                                                                        placeholder="Enter the Google sheet URL here..."
                                                                        className="w-full block focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none mb-4 draggable"
                                                                        />
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-row mx-3 -my-3 justify-between space-x-4"> 
                                                                <div className="w-full max-w-full mb-0 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                                                                    <button
                                                                        type="button"
                                                                        onClick={handleGooglesheetClick}
                                                                        className="w-full flex inline-block px-6 py-2.7 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-green-700 to-lime-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                                                                        Open Google Sheet
                                                                    </button>
                                                                </div>
                                                                <div className="w-full max-w-full mb-0 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                                                                    <button
                                                                    type="button"
                                                                    onClick={handleGooglesheetOpen}
                                                                    className="w-full flex inline-block px-6 py-2.7 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-red-700 to-red-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                                                                    Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="w-full max-w-full mb-0 sm:flex-none xl:mb-0 drop-zone flex justify-center items-center">
                                                        <button
                                                            type="button"
                                                            onClick={handleGooglesheetOpen}
                                                            className={`mx-3 w-full max-w-full justify-center items-center flex inline-block w-full px-6 py-2 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl ${googleSheet === null ? "from-blue-700 to-sky-500" : "from-yellow-700 to-orange-500"} leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs`}>
                                                            {googleSheet === null ? "SELECT GOOGLE SHEEL FILE" : "CHANGE GOOGLE SHEEL FILE"}
                                                        </button>
                                                    </div>
                                                )}
                                                {(googleSheet !== null && !googleSheetSelector) && (
                                                    <iframe  className='my-1'
                                                        src={`${googleSheet}`}
                                                        width="100%" 
                                                        style={{ height: `85vh`, boxShadow: '0px 0px 15px 5px rgba(0, 0, 0, 0.15)',  borderRadius: '30px'}}>
                                                    </iframe>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full max-w-full px-3 mb-6 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                                <div className="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-soft-xl rounded-2xl bg-clip-border mb-4">
                                    <div className="absolute right-0 mx-10 inline-block text-left" onBlur={closeDropdown} tabIndex="0">
                                        <div>
                                            <button
                                                type="button"
                                                className="inline-flex w-2/16 bg-gradient-to-tl hover:scale-102 from-red-300 to-yellow-500 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                id="menu-button"
                                                aria-expanded={isDropdownOpen}
                                                aria-haspopup="true"
                                                onClick={toggleDropdown}
                                            >
                                                {currentNumber != null ? currentNumber : "Select the Number"}
                                                <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                </svg>
                                            </button>
                                        </div>
                                        {isDropdownOpen && (
                                            <div
                                                className="absolute z-100 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="menu-button"
                                                tabIndex="-1"
                                            >
                                                <div className="py-1" role="none">
                                                    {numbers &&
                                                        numbers.numbers.map((number) => (
                                                            <p key={number} onMouseDown={() => handleNumberClick(number)} className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100" role="menuitem" tabIndex="-1" id="menu-item-0">
                                                                {number}
                                                            </p>
                                                        ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="relative flex shadow-lg flex-col min-w-0 break-words bg-white shadow-soft-xl rounded-2xl bg-clip-border mb-4 draggable" draggable="true">
                                    <div style={{ backgroundImage: `url(${BGIMAGE})`, backgroundSize: 'cover', }}
                                        className="flex-auto p-4 rounded-2xl">
                                        <div className='wavy italic mb-2 mx-3 wavy bg-gradient-to-r from-yellow-200 to-red-300 text-transparent bg-clip-text font-bold animate-bounce motion-safe'>
                                            {logMessages}
                                        </div>
                                        <div className="flex flex-row mx-3">
                                            <div className="w-full max-w-full mb-0 lg:w-8/12 sm:flex-none xl:mb-0 drop-zone">
                                                <input
                                                    type="text"
                                                    placeholder="Enter the Number"
                                                    className="w-full block focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none mb-4 draggable"
                                                    value={phoneNumber}
                                                    onChange={handleNumberEvent}
                                                />
                                            </div>
                                            <div className="w-full max-w-full mb-0 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                                                <button
                                                    type="button"
                                                    onClick={handleCall}
                                                    className={`mx-3 w-full flex inline-block w-full px-6 py-2.7 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl ${callStatus === 'idle' ? 'from-green-700 to-lime-500' : 'from-red-700 to-red-500'} leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs`}>
                                                    {callStatus === 'idle' ? 'Dial Number' : 'Hang Up'}
                                                </button>
                                            </div>
                                        </div>
                                        {callStatus === 'connected' && (
                                            <div className="flex flex-row mx-3">
                                                <div className="w-full max-w-full mb-0 lg:w-8/12 sm:flex-none xl:mb-0 drop-zone">
                                                    <input
                                                        type="text"
                                                        placeholder="Send Digits"
                                                        className="w-full block focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none mb-4 draggable"
                                                        value={dtmfDigits}
                                                        onChange={(e) => handleDigitPress(e.target.value)}
                                                    />
                                                </div>
                                                <div className="w-full max-w-full mb-0 lg:w-4/12 sm:flex-none xl:mb-0 drop-zone">
                                                    <button
                                                        type="button"
                                                        onClick={handleSendDigits}
                                                        className="mx-3 w-full flex inline-block w-full px-6 py-2.7 font-bold text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-gradient-to-tl from-red-700 to-yellow-500 leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-102 active:opacity-85 hover:shadow-soft-xs">
                                                        SEND Digit
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {logMessagesError !== "" && (
                                            <div className="flex items-center p-2 mb-2 text-sm text-red-900 rounded-xl bg-red-100 dark:bg-gray-800 dark:text-red-400" role="alert">
                                                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                                </svg>
                                                <span className="sr-only">Info</span>
                                                <div>
                                                    <span className="font-medium">{logMessagesError}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div> 
            ) : <div><InfinityLoader /></div>}
        </div>
    );
};

export default CallApp;
